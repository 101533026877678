module.exports = [{
      plugin: require('/home/ubuntu/git/lecomtoisduweb/lecomtoisduweb.github.io/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":960},
    },{
      plugin: require('/home/ubuntu/git/lecomtoisduweb/lecomtoisduweb.github.io/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Merriweather Sans","Merriweather"]}},
    },{
      plugin: require('/home/ubuntu/git/lecomtoisduweb/lecomtoisduweb.github.io/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/ubuntu/git/lecomtoisduweb/lecomtoisduweb.github.io/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-89934081-1","head":false,"anonymize":true,"respectDNT":true,"cookieDomain":"lecomtoisduweb.com"},
    },{
      plugin: require('/home/ubuntu/git/lecomtoisduweb/lecomtoisduweb.github.io/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
